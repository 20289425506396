import { Button } from '@freec/corelib/components/share/ANTD/Button'
import React, { FC } from 'react'

const FilterSelectButton: FC<{ selectedValue?: any; onChange?: any; onClear?: any; config; value? }> = ({
  selectedValue,
  onChange,
  onClear,
  config,
  value
}) => {
  return (
    <div className="d-flex flex-wrap justify-content-between">
      <style jsx global>{`
        .justify-content-between {
          justify-content: space-between;
        }
        .btn-filter.btn-active {
          color: var(--primary) !important;
          background: #fff;
          border-color: var(--primary);
          border-width: 1.5px;
        }
      `}</style>
      {config.data?.map((item, index) => {
        return (
          <Button
            onClick={() => {
              if (value == item[config.selection?.id] && config.toggleClick) {
                onClear()
              } else {
                onChange(item.id)
              }
            }}
            key={index}
            id={item[config.selection?.id]}
            className={`btn-filter ${(value || selectedValue) == item[config.selection?.id] ? 'btn-active' : ''}`}
            size={config.size ? config.size : 'large'}
            style={config.buttonStyle ? config.buttonStyle : {}}
            value={selectedValue?.toString()}>
            {item[config.selection?.name]}
          </Button>
        )
      })}
    </div>
  )
}

export default FilterSelectButton
