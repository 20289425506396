import React, { FC } from 'react'
import { Button } from '@freec/corelib/components/share/ANTD/Button'
const CurrencySelect: FC<{ onChange?: (args: string) => void; value?: string; disabledUSD?: boolean }> = ({
  onChange,
  value,
  disabledUSD = false
}) => (
  <>
    <style jsx global>{`
      .currency-select {
      }
      .currency-select .ant-btn {
        padding: 6.4px 12px;
      }
      .currency-select .btn-vnd {
        border-radius: 4px 0 0 4px;
      }
      .currency-select .btn-usd {
        border-radius: 0 4px 4px 0;
      }
    `}</style>
    <div className="currency-select">
      <Button
        size="large"
        className="btn btn-vnd"
        onClick={() => onChange('VND')}
        {...(value === 'VND' || !value ? { type: 'primary' } : {})}>
        VND
      </Button>
      <Button
        disabled={disabledUSD}
        size="large"
        className="btn btn-usd"
        onClick={() => onChange('USD')}
        {...(value === 'USD' ? { type: 'primary' } : {})}>
        USD
      </Button>
    </div>
  </>
)
export default CurrencySelect
