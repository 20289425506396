import { useState, useEffect } from 'react'
import { useClient } from '@freec/core/lib/hooks/useClient'
import { API_URL_INDUSTRIES } from '../utilities/APIConstant'
import { IndustryType } from '../type/Common'
import { useLocation as useLocationNew } from '../hooks/UseLocation'
export const useLocation = () => {
  const location = useLocationNew()
  return { location }
}

export const useIndustry: () => { industry: IndustryType[] } = () => {
  const { client } = useClient()
  const fetchIndustries = () => {
    return client.get(API_URL_INDUSTRIES, {})
  }
  const [industry, setIndustry] = useState([])
  useEffect(() => {
    fetchIndustries().then((rs) => {
      setIndustry(rs.data.data)
    })
  }, [])
  return { industry }
}
