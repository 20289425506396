import { CloseOutlined, CheckCircleFilled } from '@ant-design/icons'
import { Modal } from '@freec/corelib/components/share/ANTD/Modal'
import React, { FC, useState } from 'react'
import INTRO_PROFILE_IMAGE from '../assets/img/intro_profile.webp'
import INTRO_PROFILE_IMAGE_DESKTOP from '../assets/img/intro_profile_desktop.webp'
import Image from 'next/image'
import { useTranslation } from '@freec/corelib/hooks/UserTranslation'
import { Button } from '@freec/corelib/components/share/ANTD/Button'
import useWindowDimensions from '../hook/windowDimensionHook'
import { useAppRouter } from '@freec/corelib/hooks/UseAppRouter'
import { LINK_URL_CANDIDATES_EDIT_CV_ONLINE } from 'utilities/URL'
import { LIST_SECTION_CV_ONLINE } from '@freec/cv-onlinelib/shared/EditCVOnline/index.constants'
import { useCurrentUser } from '@freec/corelib/hooks/CurrentUserHooks'
import { Col, Row } from '@freec/corelib/components/share/ANTD/Grid'
import { useModalOpenStage, setModalVisibility } from 'hooks/CreateModalManagement'
import { Checkbox } from '@freec/corelib/components/share/ANTD/Checkbox'
import { NOT_SHOW_INTRO_PROFILE_ENRICH_MODAL } from '../Enum'
import { createUseLocalStorageStage } from '@freec/core/lib/hooks/share/StorageHooks'

const [useLocalStorageIntroProfileNotShowAgain] = createUseLocalStorageStage(NOT_SHOW_INTRO_PROFILE_ENRICH_MODAL)
const IntroProfileEnrichModal: FC = () => {
  // const [open, setOpen] = useState(true)
  const { open } = useModalOpenStage('IntroProfileEnrichModal')
  const [checkNotShow, setCheckNotShow] = useState(false)
  const { translate } = useTranslation()
  const { width } = useWindowDimensions()
  const router = useAppRouter()
  const [currentUser] = useCurrentUser()
  const [, setNotShowAgain] = useLocalStorageIntroProfileNotShowAgain()

  const handleCloseModal = () => {
    if (checkNotShow) {
      setNotShowAgain(true)
    }
    setModalVisibility(false, 'IntroProfileEnrichModal')
  }
  return (
    <>
      <style jsx global>{`
        .intro-profile .align-items-center {
          align-items: center;
        }
        .intro-profile .h-100 {
          height: 100%;
        }
        .intro-profile .ant-modal-body {
          padding: 0 0 24px 0;
        }
        .intro-profile__wrapper {
          padding: 0 17px;
        }
        .intro-profile__title {
          font-size: 18px;
          line-height: 24px;
          color: #141921;
          font-weight: bold;
          text-align: center;
          margin-bottom: 12px;
        }
        .intro-profile .intro-profile__list {
          list-style: none;
          padding-left: 0;
          margin-bottom: 16px;
          text-align: left;
        }
        .intro-profile .intro-profile__list li {
          display: flex;
          align-items: center;
        }
        .intro-profile .intro-profile__update-info {
          font-size: 14px;
          border-radius: 4px;
          margin: 0 auto;
          width: 303px;
        }

        .intro-profile .line-through {
          text-decoration: line-through;
          color: #7a7c82;
        }

        .intro-profile__not-show .ant-form-item-control-input {
          min-height: unset;
        }
        .intro-profile__check {
          min-height: unset;
          margin-right: 8px;
          font-weight: 400;
        }
        .intro-profile__check-label {
          color: #141921;
        }
        .intro-profile__check .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #36ba7e;
          border-color: #36ba7e;
        }
        .text-left {
          text-align: left;
        }
        .intro-profile__uncheck-icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          border: 1px solid #9da3ab;
          margin-right: 8px;
        }

        .intro-profile__gray-text {
          color: #7a7c82;
          font-size: 16px;
          line-height: 32px;
        }

        .intro-profile__black-text {
          color: #141921;
          font-size: 16px;
          line-height: 32px;
        }

        @media only screen and (min-width: 992px) {
          .intro-profile .ant-modal-body {
            padding: 0;
          }

          .intro-profile__wrapper {
            padding: 24px 24px 24px 0;
          }

          .intro-profile__title {
            text-align: left;
          }

          .intro-profile .intro-profile__update-info {
            width: 100%;
          }
        }
      `}</style>
      <Modal
        className="intro-profile"
        width={width > 992 ? 792 : 343}
        footer={null}
        style={{ textAlign: 'center' }}
        closeIcon={<CloseOutlined style={{ color: '#141921', fontSize: 20 }} />}
        visible={open}
        onCancel={handleCloseModal}>
        <Row>
          <Col xs={24} lg={12}>
            <div className="d-flex align-items-center h-100">
              <Image
                alt="enrich icon"
                quality={100}
                src={width > 992 ? INTRO_PROFILE_IMAGE_DESKTOP.src : INTRO_PROFILE_IMAGE.src}
                width={width > 992 ? 384 : 343}
                height={width > 992 ? 300 : 191}
              />
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="intro-profile__wrapper">
              <div className="intro-profile__title">{translate('enrich_modal:increase_your_chance')}</div>
              <ul className="intro-profile__list">
                <li>
                  {!!currentUser?.job_title && (!!currentUser?.level || currentUser?.level == '0') ? (
                    <CheckCircleFilled style={{ fontSize: 16, color: '#36ba7e', marginRight: 8 }} />
                  ) : (
                    // <PlusCircleFilled style={{ color: '#36ba7e', marginRight: 8 }} />
                    <span className="intro-profile__uncheck-icon"></span>
                  )}

                  <span
                    className={
                      !!currentUser?.job_title && (!!currentUser?.level || currentUser?.level == '0')
                        ? 'intro-profile__gray-text'
                        : 'intro-profile__black-text'
                    }>
                    {translate('enrich_modal:current_job_title')}
                  </span>
                </li>
                <li>
                  {currentUser?.work_experiences?.data?.length > 0 ? (
                    <CheckCircleFilled style={{ fontSize: 16, color: '#36ba7e', marginRight: 8 }} />
                  ) : (
                    <span className="intro-profile__uncheck-icon"></span>
                  )}
                  <span
                    className={currentUser?.work_experiences?.data?.length > 0 ? 'intro-profile__gray-text' : 'intro-profile__black-text'}>
                    {translate('enrich_modal:lastest_work_experience')}
                  </span>
                </li>
                <li>
                  {currentUser?.skills?.data?.length > 0 ? (
                    <CheckCircleFilled style={{ fontSize: 16, color: '#36ba7e', marginRight: 8 }} />
                  ) : (
                    <span className="intro-profile__uncheck-icon"></span>
                  )}
                  <span className={currentUser?.skills?.data?.length > 0 ? 'intro-profile__gray-text' : 'intro-profile__black-text'}>
                    {translate('enrich_modal:skill')}
                  </span>
                </li>

                <li>
                  {currentUser?.educations?.data?.length > 0 ? (
                    <CheckCircleFilled style={{ fontSize: 16, color: '#36ba7e', marginRight: 8 }} />
                  ) : (
                    <span className="intro-profile__uncheck-icon"></span>
                  )}
                  <span className={currentUser?.educations?.data?.length > 0 ? 'intro-profile__gray-text' : 'intro-profile__black-text'}>
                    {translate('enrich_modal:education')}
                  </span>
                </li>
              </ul>
              {width >= 992 && (
                <div className="text-left mb-4">
                  <Checkbox
                    checked={checkNotShow}
                    onChange={(value) => {
                      setCheckNotShow(value.target.checked)
                    }}
                    className="intro-profile__check d-flex align-items-center">
                    <span className="intro-profile__check-label">{translate('enrich_modal:dont_show_this_message_again')}</span>
                  </Checkbox>
                </div>
              )}
              <Button
                className="intro-profile__update-info"
                type="primary"
                size="large"
                onClick={() => {
                  if (checkNotShow) {
                    setNotShowAgain(true)
                  }
                  router.push(`${LINK_URL_CANDIDATES_EDIT_CV_ONLINE}?${LIST_SECTION_CV_ONLINE.profile.replace('edit-form__', '')}=true`)
                }}>
                {translate('enrich_modal:update_your_profile_now')}
              </Button>
              {width < 992 && (
                <div className="mt-4">
                  <Checkbox
                    checked={checkNotShow}
                    onChange={(value) => {
                      setCheckNotShow(value.target.checked)
                    }}
                    className="intro-profile__check d-flex align-items-center">
                    <span className="intro-profile__check-label">{translate('enrich_modal:dont_show_this_message_again')}</span>
                  </Checkbox>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default IntroProfileEnrichModal
