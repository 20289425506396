import { useEffect, useState } from 'react'

type ModalType = 'UserInfoEnrichModal' | 'IntroCVOEnrichModal' | 'IntroProfileEnrichModal' | 'LookingForJobModal'

const createModalManagement = () => {
  let modalArray: { modalName: string; openState: boolean; callBackOpen: (stage: boolean) => void; isWaiting4Open: boolean }[] = []

  const registerModal = (modalName: ModalType, callBackOpen: (stage: boolean) => void) => {
    // if (modalArray.length === 0) {
    //   callBackOpen(true)
    //   modalArray = [...modalArray, { modalName, callBackOpen, openState: true, isWaiting4Open: false }]
    // } else {
    modalArray = [...modalArray, { modalName, callBackOpen, openState: false, isWaiting4Open: false }]
    if (modalArray.length === 1) {
      setModalVisibility(true, modalName)
    }
    // }
  }
  const unRegisterModal = (modalName: ModalType) => {
    modalArray = modalArray.filter((item) => item.modalName != modalName)
  }

  const setModalVisibility = (visible: boolean, modalName: ModalType) => {
    const modal = modalArray.find((item) => item.modalName === modalName)
    const modalIsShowing = modalArray.find((item) => item.openState)
    // open

    if (modalIsShowing && modalIsShowing.modalName !== modalName) {
      modalArray = modalArray.map((item) => (modalName === item.modalName ? { ...item, openState: visible, isWaiting4Open: true } : item))
    } else {
      modal && modal.callBackOpen(visible)
    }
    // close
    if (visible === false) {
      modalArray = modalArray.filter((item) => item.modalName !== modalName) //remove closing modal

      const nextModal = modalArray.find((item) => item.isWaiting4Open)
      if (nextModal) {
        modalArray = modalArray.map((item) => (nextModal.modalName === item.modalName ? { ...item, openState: true } : item))
        nextModal.callBackOpen(true)
      }
    }
  }

  //hooks
  const useModalOpenStage = (modalName: ModalType) => {
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
      registerModal(modalName, setOpen) //register modal

      return () => {
        unRegisterModal(modalName)
      }
    }, [])

    return { open }
  }
  return {
    setModalVisibility,
    useModalOpenStage
  }
}
export const { setModalVisibility, useModalOpenStage } = createModalManagement()
