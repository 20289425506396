export const LIST_SECTION_CV_ONLINE = {
  profile: 'edit-form__profile',
  workExperience: 'edit-form__workExperience',
  skills: 'edit-form__skills',
  languages: 'edit-form__languages',
  educations: 'edit-form__educations',
  certificates: 'edit-form__certificates',
  workReferences: 'edit-form__workReferences',
  achievements: 'edit-form__achievements',
  referencers: 'edit-form__referencers'
}

export const DEFAULT_TEMPLATE = 1

export const LIST_TEMPLATE = [
  { id: 0, url: '' },
  { id: 1, url: '/template/release/template_default.webp' },
  { id: 2, url: '/template/release/template_2.webp' },
  { id: 3, url: '/template/release/template_3.webp' },
  { id: 4, url: '/template/release/template_4.webp' },
  { id: 5, url: '/template/release/template_5.webp' },
  { id: 6, url: '/template/release/template_6.webp' },
  { id: 7, url: '/template/release/template_7.webp' },
  { id: 8, url: '/template/release/template_8.webp' },
  { id: 9, url: '/template/release/template_9.webp' },
  { id: 10, url: '/template/release/template_10.webp' },
  { id: 15, url: '/template/release/template_15.webp' },
  { id: 16, url: '/template/release/template_16.webp' },
  { id: 17, url: '/template/release/template_17.webp' }
]

export const LIST_PLATFORM = [
  { id: 0, name: 'Other' },
  { id: 1, name: 'Linkedin' },
  { id: 2, name: 'GitHub' },
  { id: 3, name: 'Behance' },
  { id: 4, name: 'Dribbble' },
  { id: 5, name: 'Facebook' },
  { id: 6, name: 'Twitter' },
  { id: 7, name: 'Instagram' },
  { id: 8, name: 'Youtube' },
  { id: 9, name: 'GitLab' }
]

export const GENDER = [
  { id: 1, gender: 'Male', gender_vn: 'Anh' },
  { id: 2, gender: 'Female', gender_vn: 'Chị' },
  { id: 3, gender: 'Other', gender_vn: 'Khác' }
]
export const GENDER_TEXT = {
  1: { id: 1, gender: 'Male', gender_vn: 'Anh' },
  2: { id: 2, gender: 'Female', gender_vn: 'Chị' },
  3: { id: 3, gender: 'Other', gender_vn: 'Khác' }
}

export const AVAILABILITY_DAYS = [
  { id: 0, value: 0, text: 'Available Now', text_vn: 'Đi Làm Ngay' },
  { id: 1, value: 15, text: '15 days', text_vn: '15 Ngày' },
  { id: 2, value: 30, text: '30 days', text_vn: '30 Ngày' },
  { id: 3, value: 45, text: '45 days', text_vn: '45 Ngày' },
  { id: 4, value: 60, text: '60 days', text_vn: '60 Ngày' },
  { id: 5, value: 90, text: '90 days', text_vn: '90 Ngày' }
]
