import React, { FC, useState, useEffect } from 'react'
// import IntroCVOEnrichModal from './components/IntroCVOEnrichModal'
import IntroProfileEnrichModal from './components/IntroProfileEnrichModal'
import UserInfoEnrichModal from './components/UserInfoEnrichModal'
import { dayjs } from '@freec/core/lib/utilities/dayjs'
// import { useFetchAndStoreUser } from '@freec/corelib/hooks/CurrentUserHooks'

import {
  TIME_ENTER_WEBSITE_SESSION_NAME,
  IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME,
  // IS_SHOW_INTRO_CVO_ENRICH_MODAL_SESSION_NAME,
  IS_SHOW_INTRO_PROFILE_ENRICH_MODAL_SESSION_NAME,
  PREVIOUS_SHOWED_TIME_USER_MODAL,
  // PREVIOUS_SHOWED_TIME_INTRO_CVO_MODAL,
  PREVIOUS_SHOWED_TIME_INTRO_PROFILE_MODAL,
  FIRST_TIME_ENTER_WEBSITE,
  NOT_SHOW_USER_INFO_ENRICH_MODAL,
  NOT_SHOW_INTRO_PROFILE_ENRICH_MODAL
} from './Enum'
import { LINK_URL_ONBOARDING_CANDIDATE } from '@freec/corelib/utilities/LinkURL'
import { useAppRouter } from '@freec/corelib/hooks/UseAppRouter'
import { createUseLocalStorageStage } from '@freec/core/lib/hooks/share/StorageHooks'
import useIsLogin from '@freec/optimal-pagehooks/useIsLogin'
import { LINK_URL_CANDIDATES_EDIT_CV_ONLINE, LINK_URL_LOGIN } from 'utilities/URL'
import LookingForJobModal from './components/LookingForJobModal'
import { ProfileType } from '@freec/optimal-page/types/server/user/ProfileType'
import { useCurrentUser } from '@freec/corelib/hooks/CurrentUserHooks'

const [useLocalStorageUserModalState] = createUseLocalStorageStage(PREVIOUS_SHOWED_TIME_USER_MODAL)
// const [useLocalStorageIntroCVOModalState] = createUseLocalStorageStage(PREVIOUS_SHOWED_TIME_INTRO_CVO_MODAL)
const [useLocalStorageIntroProfileModalState] = createUseLocalStorageStage(PREVIOUS_SHOWED_TIME_INTRO_PROFILE_MODAL)
const [useLocalStorageFirstTimeEnterWebsite] = createUseLocalStorageStage(FIRST_TIME_ENTER_WEBSITE)

const [useLocalStorageUserInfoNotShowAgain] = createUseLocalStorageStage(NOT_SHOW_USER_INFO_ENRICH_MODAL)
const [useLocalStorageIntroProfileNotShowAgain] = createUseLocalStorageStage(NOT_SHOW_INTRO_PROFILE_ENRICH_MODAL)

type PropsCondition = {
  profile?: ProfileType
  timeShowedUserModal?: unknown
  setTimeShowedUserModal?: (stage: unknown) => void
  timeShowedIntroProfileModal?: unknown
  setTimeShowedIntroProfileModal?: (stage: unknown) => void
  notShowUserInfoAgain: unknown
  setNotShowUserInfoAgain: (stage: unknown) => void
  notShowIntroProfileAgain: unknown
  setNotShowIntroProfileAgain: (stage: unknown) => void
}

type ModalType = {
  [index: number]: {
    name: string
    Component: (props?: { profile?: ProfileType }) => JSX.Element
    tto: number
    triggerCondition: (props: PropsCondition) => boolean
  }
}

const modalPine: ModalType = {
  0: {
    name: 'LookingForJobModal',
    Component: () => {
      return <LookingForJobModal />
    },
    tto: 60000,
    triggerCondition: (props: any) => {
      const { profile } = props

      const diffFromLastShowed = profile.desire_change_job_changed_at
        ? dayjs().diff(String(profile.desire_change_job_changed_at), 'days')
        : 60
      const condition = !profile.desire_change_job && diffFromLastShowed >= 60

      return condition
    }
  },
  1: {
    name: 'UserInfoEnrichModal',
    Component: (props: { profile: ProfileType }) => {
      const { profile } = props
      return <UserInfoEnrichModal profile={profile} />
    },
    tto: 90000,
    triggerCondition: (props: PropsCondition) => {
      const { profile, timeShowedUserModal, setTimeShowedUserModal, notShowUserInfoAgain, setNotShowUserInfoAgain } = props
      let diffFromLastShowedUserModal = timeShowedUserModal ? dayjs().diff(String(timeShowedUserModal), 'millisecond') : 0
      const monthsFromLastShowedUserModal = timeShowedUserModal ? dayjs().diff(String(timeShowedUserModal), 'months', true) : undefined
      const TwoDaysInMiliSeconds = 48 * 3600 * 1000

      if (diffFromLastShowedUserModal >= TwoDaysInMiliSeconds && !notShowUserInfoAgain) {
        diffFromLastShowedUserModal = undefined
        setTimeShowedUserModal(undefined)
        sessionStorage.removeItem(IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME)
      }

      let condition = true
      if ((monthsFromLastShowedUserModal || monthsFromLastShowedUserModal === 0) && monthsFromLastShowedUserModal <= 3)
        condition = condition && !notShowUserInfoAgain
      else setNotShowUserInfoAgain(false)

      if (sessionStorage.getItem(IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME) === 'false' && !timeShowedUserModal) {
        setTimeShowedUserModal(String(dayjs()))
      }

      condition =
        condition &&
        (!diffFromLastShowedUserModal || sessionStorage.getItem(IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME) !== 'false') &&
        (!profile.job_title ||
          !profile.expected_salary_cents ||
          !profile.salary_currency ||
          !profile.location_id ||
          profile.expected_salary_cents === 0)

      if (condition) {
        setTimeShowedUserModal(String(dayjs()))
        sessionStorage.setItem(IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME, 'false')
      }
      return condition
    }
  },
  // 2: {
  //   name: 'IntroCVOEnrichModal',
  //   Component: () => <IntroCVOEnrichModal />,
  //   triggerCondition: (props: any) => {
  //     const { profile, timeShowedUserModal, setTimeShowedIntroCVOModal, timeShowedIntroCVOModal } = props
  //     let diffFromLastShowedIntroCVOModal = timeShowedIntroCVOModal
  //       ? dayjs().diff(String(timeShowedIntroCVOModal), 'millisecond')
  //       : undefined
  //     const diffFromLastShowedUserModal = timeShowedUserModal ? dayjs().diff(String(timeShowedUserModal), 'millisecond') : undefined
  //     const OneDayInMiliSeconds = 24 * 3600 * 1000
  //     const TwoHoursInMiliSeconds = 2 * 3600 * 1000

  //     if (diffFromLastShowedIntroCVOModal >= OneDayInMiliSeconds) {
  //       diffFromLastShowedIntroCVOModal = undefined
  //       setTimeShowedIntroCVOModal(undefined)
  //       sessionStorage.removeItem(IS_SHOW_INTRO_CVO_ENRICH_MODAL_SESSION_NAME)
  //     }

  //     let checkSessionIntroCVO = sessionStorage.getItem(IS_SHOW_INTRO_CVO_ENRICH_MODAL_SESSION_NAME) !== 'false'
  //     if (diffFromLastShowedUserModal) checkSessionIntroCVO = checkSessionIntroCVO && diffFromLastShowedUserModal >= TwoHoursInMiliSeconds

  //     const condition =
  //       (!profile.template || profile.template === 1) &&
  //       profile.work_experiences.data.length === 0 &&
  //       (!diffFromLastShowedIntroCVOModal || checkSessionIntroCVO)

  //     if (condition) {
  //       setTimeShowedIntroCVOModal(String(dayjs()))
  //       sessionStorage.setItem(IS_SHOW_INTRO_CVO_ENRICH_MODAL_SESSION_NAME, 'false')
  //     }
  //     return condition
  //   }
  // },
  2: {
    name: 'IntroProfileEnrichModal',
    Component: () => <IntroProfileEnrichModal />,
    tto: 90000,
    triggerCondition: (props: PropsCondition) => {
      const {
        profile,
        timeShowedUserModal,
        timeShowedIntroProfileModal,
        setTimeShowedIntroProfileModal,
        notShowIntroProfileAgain,
        setNotShowIntroProfileAgain,
        notShowUserInfoAgain
      } = props
      let diffFromLastShowedIntroProfileModal = timeShowedIntroProfileModal
        ? dayjs().diff(String(timeShowedIntroProfileModal), 'millisecond')
        : undefined
      const monthsFromLastShowedIntroProfileModal = timeShowedIntroProfileModal
        ? dayjs().diff(String(timeShowedIntroProfileModal), 'months', true)
        : undefined
      const daysFromLastShowedUserInfoModal = timeShowedUserModal ? dayjs().diff(String(timeShowedUserModal), 'day', true) : undefined
      const TwoDaysInMiliSeconds = 48 * 3600 * 1000

      if (diffFromLastShowedIntroProfileModal >= TwoDaysInMiliSeconds && !notShowIntroProfileAgain) {
        diffFromLastShowedIntroProfileModal = undefined
        setTimeShowedIntroProfileModal(undefined)
        sessionStorage.removeItem(IS_SHOW_INTRO_PROFILE_ENRICH_MODAL_SESSION_NAME)
      }

      let condition = true
      if (
        (monthsFromLastShowedIntroProfileModal || monthsFromLastShowedIntroProfileModal === 0) &&
        monthsFromLastShowedIntroProfileModal <= 3
      )
        condition = !notShowIntroProfileAgain
      else setNotShowIntroProfileAgain(false)

      const isClosedUserInfoModal =
        notShowUserInfoAgain ||
        (!!profile.job_title && !!profile.salary_currency && profile.expected_salary_cents !== 0 && !!profile.location_id)

      if (daysFromLastShowedUserInfoModal) condition = condition && daysFromLastShowedUserInfoModal >= 2

      condition =
        condition &&
        sessionStorage.getItem(IS_SHOW_INTRO_PROFILE_ENRICH_MODAL_SESSION_NAME) !== 'false' &&
        isClosedUserInfoModal &&
        (!(profile.level || profile.level === 0) ||
          profile?.work_experiences?.data.length <= 0 ||
          profile?.skills?.data.length <= 0 ||
          profile?.educations.data.length <= 0)

      if (condition) {
        setTimeShowedIntroProfileModal(String(dayjs()))
        sessionStorage.setItem(IS_SHOW_INTRO_PROFILE_ENRICH_MODAL_SESSION_NAME, 'false')
      }

      return condition
    }
  }
}

const ManageComponent = (modalPine: ModalType) => {
  const router = useAppRouter()
  const [enrichModal, setEnrichModal] = useState<any>()
  const [profile, setProfile] = useState<ProfileType>()
  const [notShowUserInfoAgain, setNotShowUserInfoAgain] = useLocalStorageUserInfoNotShowAgain()
  const [notShowIntroProfileAgain, setNotShowIntroProfileAgain] = useLocalStorageIntroProfileNotShowAgain()
  const [curentUser] = useCurrentUser()

  // const fetchAndStoreUser = useFetchAndStoreUser()

  const [timeShowedUserModal, setTimeShowedUserModal] = useLocalStorageUserModalState()
  // const [timeShowedIntroCVOModal, setTimeShowedIntroCVOModal] = useLocalStorageIntroCVOModalState()
  const [timeShowedIntroProfileModal, setTimeShowedIntroProfileModal] = useLocalStorageIntroProfileModalState()
  const [firstTimeEnterWebsite, setFirstTimeEnterWebsite] = useLocalStorageFirstTimeEnterWebsite()

  const propsCondition = {
    profile,
    timeShowedUserModal,
    setTimeShowedUserModal,
    // timeShowedIntroCVOModal,
    // setTimeShowedIntroCVOModal,
    timeShowedIntroProfileModal,
    setTimeShowedIntroProfileModal,
    notShowUserInfoAgain,
    setNotShowUserInfoAgain,
    notShowIntroProfileAgain,
    setNotShowIntroProfileAgain
  }

  const propsComponent = {
    profile
  }

  useEffect(() => {
    if (![LINK_URL_LOGIN].includes(router.pathname)) {
      // !profile && fetchAndStoreUser().then((profile) => setProfile(profile))
      setProfile(curentUser)
    }
  }, [curentUser])

  useEffect(() => {
    if (profile) {
      let timeOut
      if (typeof window !== 'undefined' && useIsLogin()) {
        const enterWebsiteTime = !sessionStorage.getItem(TIME_ENTER_WEBSITE_SESSION_NAME)
          ? String(dayjs())
          : sessionStorage.getItem(TIME_ENTER_WEBSITE_SESSION_NAME)
        sessionStorage.setItem(TIME_ENTER_WEBSITE_SESSION_NAME, enterWebsiteTime)
        if (!firstTimeEnterWebsite) setFirstTimeEnterWebsite(enterWebsiteTime)
        const tempFirstTimeEnterWebsite = String(firstTimeEnterWebsite || enterWebsiteTime)

        const timeStayingWebsite = dayjs().diff(enterWebsiteTime, 'millisecond')
        const daysFromTheFirstTimeEnterWebsite = dayjs().diff(tempFirstTimeEnterWebsite, 'day')

        const isInOnboardingPage = router.pathname === LINK_URL_ONBOARDING_CANDIDATE
        const isInProfileEditPage = router.pathname.includes(LINK_URL_CANDIDATES_EDIT_CV_ONLINE)
        const condition = !isInOnboardingPage && !isInProfileEditPage

        if (condition && daysFromTheFirstTimeEnterWebsite >= 2 && profile?.is_on_boarding_success) {
          Object.values(modalPine).reduce<() => void>(
            (lastFunction, item) => {
              return () => {
                timeOut = setTimeout(
                  () => {
                    const conditionOpenModal = item?.triggerCondition(propsCondition)

                    if (conditionOpenModal) {
                      setEnrichModal(item?.Component(propsComponent))
                    } else {
                      lastFunction()
                    }
                  },
                  item.tto - timeStayingWebsite < 0 ? 0 : item.tto - timeStayingWebsite
                )
              }
            },
            () => false
          )()
        }
      }

      return () => {
        timeOut && clearTimeout(timeOut)
      }
    }
  }, [profile])

  return enrichModal ? <>{enrichModal}</> : null
}

const EnrichModal: FC = () => {
  return <>{ManageComponent(modalPine)}</>
}

export default EnrichModal
