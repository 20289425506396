import React, { FC } from 'react'
import { Modal } from '@freec/core/lib/components/share/ANTD/Modal'
import useWindowDimensions from '../hook/windowDimensionHook'
import Image from 'next/dist/client/image'
import { useModalOpenStage, setModalVisibility } from 'hooks/CreateModalManagement'
import { CloseOutlined } from '@ant-design/icons'
import Looking4JobImage from '../assets/img/looking_for_job.png'
import { Switch } from '@freec/corelib/components/share/ANTD/Switch'
import { useForm } from 'antd/lib/form/Form'
import { Form } from '@freec/corelib/components/share/ANTD/Form'
import { setCurrentuser, useCurrentUser } from '@freec/corelib/hooks/CurrentUserHooks'
import client from '@freec/corelib/utilities/Client'

const LookingForJobModal: FC = () => {
  const [form] = useForm()
  const { width } = useWindowDimensions()
  const { open } = useModalOpenStage('LookingForJobModal')
  const [curentUser] = useCurrentUser()
  const profileN = curentUser

  const onFinish = () => {
    const desire_change_job = form.getFieldsValue(['desire_change_job'])['desire_change_job'] || false

    client
      .put(`/api/v2/candidates/profile/trigger_desire_change_job`, {
        data: { desire_change_job }
      })
      .then(() => {
        setModalVisibility(false, 'LookingForJobModal')
        setCurrentuser({ profile: { ...profileN, desire_change_job } })
      })
  }
  return (
    <>
      <style jsx global>{`
        .l4j-modal .ant-modal-body {
          padding: 0;
        }

        .l4j-modal__content {
          padding: 24px 16px;
        }

        .l4j-modal__title {
          font-size: 18px;
          line-height: 24px;
          color: #141921;
          font-weight: bold;
          margin-bottom: 4px;
        }

        .l4j-modal__summary {
          color: #7a7c82;
          margin-bottom: 16px;
        }

        .l4j-modal__footer {
          padding: 8px 12px !important;
          background-color: #f9fafc;
          border-radius: 4px;
        }

        .l4j-modal__open-to-work {
          font-size: 16px;
          line-height: 24px;
          color: #141921;
          font-weight: 500;
        }

        .l4j-modal__footer .l4j-modal__switch {
          min-width: 52px;
          height: 32px;
        }
        .l4j-modal__switch .ant-switch-handle {
          width: 28px;
          height: 28px;
        }
        .l4j-modal__switch .ant-switch-handle::before {
          border-radius: 14px;
        }
        .l4j-modal__switch.ant-switch-checked {
          background-color: #36ba7e;
        }
        .l4j-modal__switch.ant-switch-checked .ant-switch-handle {
          left: calc(100% - 28px - 2px);
        }
        .l4j-modal__footer .ant-form-item-control-input {
          min-height: unset;
        }

        @media only screen and (min-width: 992px) {
          .l4j-modal__footer .l4j-modal__switch {
            min-width: 32px;
            height: 20px;
          }
          .l4j-modal__switch .ant-switch-handle {
            width: 16px;
            height: 16px;
          }
          .l4j-modal__switch.ant-switch-checked {
            background-color: #36ba7e;
          }
          .l4j-modal__switch.ant-switch-checked .ant-switch-handle {
            left: calc(100% - 16px - 2px);
          }
        }
      `}</style>
      <Modal
        className="l4j-modal"
        width={width > 992 ? 384 : 343}
        footer={null}
        style={{ textAlign: 'center' }}
        closeIcon={<CloseOutlined style={{ color: '#141921', fontSize: 20 }} />}
        visible={open}
        onCancel={() => {
          onFinish()
        }}>
        <div>
          <Image
            alt="looking for job image"
            width={width > 992 ? 384 : 343}
            height={width > 992 ? 253 : 226}
            src={width > 992 ? Looking4JobImage.src : Looking4JobImage.src}
          />
        </div>
        <div className="l4j-modal__content">
          <div className="l4j-modal__title">Are you ready for a new opportunity?</div>
          <div className="l4j-modal__summary">
            Turn on “Open to work” status so employer can actively search & contact you to discuss new opportunities
          </div>
          <Form
            form={form}
            className="d-flex align-items-center justify-content-between l4j-modal__footer"
            layout="vertical"
            onFinish={onFinish}>
            <div className="l4j-modal__open-to-work">Open to work</div>
            <Form.Item className="mb-0" name="desire_change_job">
              <Switch defaultChecked={false} className="l4j-modal__switch" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default LookingForJobModal
