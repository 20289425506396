import React, { FC, useState, useEffect } from 'react'
import { Modal } from '@freec/core/lib/components/share/ANTD/Modal'
import Image from 'next/dist/client/image'

import UserInfoImage from '../assets/img/user_info.webp'
import UserInfoImageDesktop from '../assets/img/user_info_desktop.webp'
import { CloseOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'
import { Form } from '@freec/corelib/components/share/ANTD/Form'
import { useTranslation } from '@freec/corelib/hooks/UserTranslation'
import { Input } from '@freec/corelib/components/share/ANTD/Input'
import FilterSelectButton from './FilterSelectButton'
import { useLocation } from '@freec/corelib/hooks/Industry'
import { Select } from '@freec/corelib/components/share/ANTD/Select'
import { removeAccents } from '@freec/corelib/utilities/common'
import { InputNumber } from '@freec/corelib/components/share/ANTD/InputNumber'
import { Button } from '@freec/corelib/components/share/ANTD/Button'
import CurrencySelect from './CurrencySelect'
import { useClient } from '@freec/corelib/hooks/useClient'
import useWindowDimensions from '../hook/windowDimensionHook'
import { Col, Row } from '@freec/corelib/components/share/ANTD/Grid'
import { useFetchAndStoreUser } from '@freec/corelib/hooks/CurrentUserHooks'
import { useModalOpenStage, setModalVisibility } from 'hooks/CreateModalManagement'
import { Checkbox } from '@freec/corelib/components/share/ANTD/Checkbox'
import { createUseLocalStorageStage } from '@freec/core/lib/hooks/share/StorageHooks'
import { NOT_SHOW_USER_INFO_ENRICH_MODAL } from '../Enum'

const [useLocalStorageUserInfoNotShowAgain] = createUseLocalStorageStage(NOT_SHOW_USER_INFO_ENRICH_MODAL)
const UserInfoEnrichModal: FC<{ profile: any }> = ({ profile }) => {
  const [form] = useForm()
  const { client } = useClient()
  const { currentLanguage, translate } = useTranslation()
  const { location } = useLocation()
  const { open } = useModalOpenStage('UserInfoEnrichModal')
  // const [open, setOpen] = useState(true)
  const [locationData, setLocationData] = useState<any>()
  const [userInfo, setUserInfoData] = useState<any>()
  const [isShowOtherLocations, setIsShowOtherLocations] = useState(false)
  const { width } = useWindowDimensions()
  const fetchProfile = useFetchAndStoreUser()
  const [, setNotShowModalAgain] = useLocalStorageUserInfoNotShowAgain()

  useEffect(() => {
    setUserInfoData({
      job_title: profile?.job_title,
      expected_salary_cents:
        profile?.expected_salary_cents !== 0 || profile?.expected_salary_cents ? profile?.expected_salary_cents : undefined,
      salary_currency: profile?.salary_currency,
      location_id: profile?.location_id ? String(profile?.location_id) : undefined
    })

    if (profile?.location_id && profile?.location_id != 27 && profile?.location_id != 22 && profile?.location_id != 14) {
      setIsShowOtherLocations(true)
    }
  }, [])

  useEffect(() => {
    if (location?.data) {
      const locationCentral = location?.data
        ?.filter((locationItem) => locationItem.id == '27' || locationItem.id == '22' || locationItem.id == '14')
        ?.map((item) => {
          return {
            ...item,
            ...{ state: item.attributes.state },
            ...{ state_vn: item.attributes.state_vn }
          }
        })
      setLocationData([
        ...(locationCentral ? locationCentral : []),
        {
          id: '999',
          state: 'Other',
          state_vn: 'Khác'
        }
      ])
    }
  }, [location])

  const onFinish = (data) => {
    if (data.not_show_again) {
      setNotShowModalAgain(true)
    }

    if (data.location != 999 && data.location) data = { ...data, ...{ location_id: data.location } }

    if (data.location_id || data.job_title || data.salary_currency || data.expected_salary_cents || data.expected_salary_cents === 0) {
      data.desire_change_job = true
      data.expected_salary_cents = data.expected_salary_cents || 0

      client.post(`/api/v2/candidates/profile`, { data }).then(() => {
        fetchProfile()
      })
    }

    setModalVisibility(false, 'UserInfoEnrichModal')
  }

  return userInfo ? (
    <>
      <style jsx global>{`
        .user-info .ant-modal-body {
          padding: 0;
        }

        .user-info .user-info__form {
          padding: 0 24px 24px;
        }

        .modal__title {
          font-size: 18px;
          line-height: 24px;
          color: #141921;
          font-weight: bold;
          max-width: 220px;
          margin: 0 auto 16px;
        }
        .user-info .ant-select-selection-placeholder {
          text-align: left !important;
        }
        .user-info .btn-vnd,
        .user-info .btn-usd {
          width: 50%;
          padding: 0 !important;
        }
        .user-info .btn-vnd {
          border-radius: 4px 0 0 4px;
        }

        .user-info .btn-usd {
          border-radius: 0 4px 4px 0;
        }
        .enrich-modal__label {
          font-size: 14px;
          line-height: 22px;
          color: #141921;
          font-weight: 500;
          text-align: left;
          margin-bottom: 8px;
          margin-top: 24px;
        }
        .mb-16-px {
          margin-bottom: 16px !important;
        }
        .user-info__submit-btn {
          width: 100%;
          border-radius: 0;
          height: 40px !important;
          padding: 8px 15px;
        }

        .user-info input::placeholder,
        .user-info .ant-select-selection-placeholder {
          color: #7a7c82;
        }

        .user-info label {
          color: #141921;
          font-weight: 500;
        }

        .user-info__not-show .ant-form-item-control-input {
          min-height: unset;
        }
        .user-info__form .user-info__check {
          min-height: unset;
          margin-right: 8px;
          font-weight: 400;
        }
        .user-info__check-label {
          color: #141921;
        }
        .user-info__form .user-info__check .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #36ba7e;
          border-color: #36ba7e;
        }
        .text-left {
          text-align: left;
        }
        .user-info__form .ant-form-item-label {
          padding-bottom: 8px !important;
        }

        @media only screen and (min-width: 992px) {
          .modal__image {
            margin-bottom: 0;
          }

          .user-info .ant-modal-body {
            padding: 0;
          }

          .user-info .user-info__form {
            padding: 24px 24px 24px 0;
          }

          .modal__title {
            max-width: unset;
            text-align: left;
          }

          .user-info .ant-btn-lg {
            padding: 6.4px 16px;
          }

          .user-info__submit-btn {
            border-radius: 4px;
          }
        }
      `}</style>
      <Modal
        className="user-info"
        width={width > 992 ? 792 : 343}
        footer={null}
        style={{ textAlign: 'center' }}
        closeIcon={<CloseOutlined style={{ color: '#141921', fontSize: 20 }} />}
        visible={open}
        onCancel={() => setModalVisibility(false, 'UserInfoEnrichModal')}>
        <Row>
          <Col xs={24} lg={12}>
            <div className="modal__image">
              <Image
                alt="enrich icon"
                width={width > 992 ? 384 : 343}
                height={width > 992 ? 410 : 191}
                src={width > 992 ? UserInfoImageDesktop.src : UserInfoImage.src}
              />
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="user-info__form">
              <div className="modal__title">{translate('enrich_modal:enrich_modal_title')}</div>
              <Form form={form} initialValues={userInfo} name="job_alert_modal_form" layout="vertical" onFinish={onFinish}>
                {/* {!userInfo?.job_title && ( */}
                <Form.Item name="job_title" label={translate('enrich_modal:job_position')} className="mb-4">
                  <Input placeholder={translate('enrich_modal:job_position_example')} size="large" />
                </Form.Item>
                {/* )} */}
                {/* {!userInfo?.location_id && ( */}
                <Form.Item className="mb-0" name="location" label={translate('enrich_modal:where_do_you_live_now')}>
                  <FilterSelectButton
                    selectedValue={
                      profile?.location_id && profile?.location_id != 27 && profile?.location_id != 22 && profile?.location_id != 14
                        ? '999'
                        : String(profile?.location_id)
                    }
                    config={{
                      size: 'large',
                      buttonStyle: {
                        fontSize: 14,
                        color: '#4f535b',
                        marginBottom: 8
                      },
                      currentLanguage,
                      selection: {
                        id: 'id',
                        name: currentLanguage === 'en' ? 'state' : 'state_vn'
                      },
                      data: locationData
                      // toggleClick: true
                    }}
                    onChange={(value) => {
                      value == 999 ? setIsShowOtherLocations(true) : setIsShowOtherLocations(false)
                    }}
                    onClear={() => {
                      // form.resetFields(['location'])
                      // setSelectedLocationId(undefined)
                      // setIsShowOtherLocations(false)
                    }}
                  />
                </Form.Item>
                {/* )} */}
                {isShowOtherLocations && location?.data?.length > 0 && (
                  <Form.Item className="mb-16-px" name="location_id">
                    <Select
                      showSearch
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      filterOption={(input, option) =>
                        removeAccents(option.children.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >= 0
                      }
                      size="large"
                      placeholder={translate('enrich_modal:select_city_province')}>
                      {location?.data?.map((location) => (
                        <Select.Option value={location.id} key={location.id}>
                          {currentLanguage === 'en' ? location.attributes.state : location.attributes.state_vn}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {/* {(!userInfo?.expected_salary_cents || userInfo?.expected_salary_cents === 0 || !userInfo?.salary_currency) && ( */}

                <div className="enrich-modal__label">{translate('enrich_modal:expected_salary')}</div>
                <Row gutter={[8, 0]}>
                  <Col xs={15} lg={16}>
                    <Form.Item name="expected_salary_cents">
                      <InputNumber
                        size="large"
                        placeholder={translate('enrich_modal:enrich_salary_ex')}
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={9} lg={8}>
                    <Form.Item name="salary_currency">
                      <CurrencySelect value="USD" />
                    </Form.Item>
                  </Col>
                </Row>

                {/* )} */}
                {width >= 992 && (
                  <Form.Item className="text-left user-info__not-show" name="not_show_again">
                    <Checkbox
                      onChange={(value) => {
                        form.setFieldsValue({ not_show_again: value.target.checked })
                      }}
                      className="user-info__check d-flex align-items-center">
                      <span className="user-info__check-label">{translate('enrich_modal:dont_show_this_message_again')}</span>
                    </Checkbox>
                  </Form.Item>
                )}

                <Button className="user-info__submit-btn" htmlType="submit" type="primary">
                  {translate('enrich_modal:update_information')}
                </Button>
                {width < 992 && (
                  <Form.Item className="user-info__not-show mt-4 mb-0" name="not_show_again">
                    <Checkbox defaultChecked={userInfo.not_show_again} className="user-info__check d-flex align-items-center">
                      <span className="user-info__check-label">{translate('enrich_modal:dont_show_this_message_again')}</span>
                    </Checkbox>
                  </Form.Item>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  ) : null
}

export default UserInfoEnrichModal
