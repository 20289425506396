export const TIME_ENTER_WEBSITE_SESSION_NAME = 'time_enter_website'
export const IS_SHOW_USER_INFO_ENRICH_MODAL_SESSION_NAME = 'is_show_user_info_enrich_modal'
export const IS_SHOW_INTRO_CVO_ENRICH_MODAL_SESSION_NAME = 'is_show_intro_cvo_enrich_modal'
export const IS_SHOW_INTRO_PROFILE_ENRICH_MODAL_SESSION_NAME = 'is_show_intro_profile_enrich_modal'

export const PREVIOUS_SHOWED_TIME_USER_MODAL = '@previous_showed_time_user_modal'
export const PREVIOUS_SHOWED_TIME_INTRO_CVO_MODAL = '@previous_showed_time_intro_cvo_modal'
export const PREVIOUS_SHOWED_TIME_INTRO_PROFILE_MODAL = '@previous_showed_time_intro_profile_modal'

export const FIRST_TIME_ENTER_WEBSITE = '@first_time_enter_website'
export const NOT_SHOW_USER_INFO_ENRICH_MODAL = '@not_show_user_info_enrich_modal'
export const NOT_SHOW_INTRO_PROFILE_ENRICH_MODAL = '@not_show_intro_profile_enrich_modal'
